import { Link } from 'gatsby'
import { gql } from '@apollo/client'
import { css } from '@emotion/react'
import { media, theme } from '@dfds-ui/theme'
import { Query } from '@apollo/client/react/components'
import { Headline, Spinner } from '@dfds-ui/react-components'

import { FlexCard } from '../Card'
import { AspectImage } from '../Image'
import { formatDate } from '../../utils'
import { useLocaleContext } from '../LocaleContext'

const GET_ARTICLES_WITH_TAG = gql`
  query GetArticleIdsByTag($tag: String!) {
    tagCollection(where: { tagName: $tag }) {
      items {
        tagName
        linkedFrom {
          newsArticleCollection(limit: 100) {
            items {
              sys {
                id
                firstPublishedAt
              }
            }
          }
        }
      }
    }
  }
`

const GET_ARTICLE_BY_ID = gql`
  query GetArticlesById($id: String!) {
    newsArticleCollection(limit: 1, where: { sys: { id: $id } }) {
      items {
        sys {
          id
          firstPublishedAt
        }
        linkedFrom {
          urlSlugCollection(limit: 1) {
            items {
              slug
            }
          }
        }
        title
        subtitle
        location
        image {
          title
          description
          contentType
          url
          width
          height
        }
      }
    }
  }
`

const RelatedContent = (props) => {
  const { localeLower } = useLocaleContext()

  return (
    <Query query={GET_ARTICLES_WITH_TAG} variables={{ tag: props.tag }}>
      {({ loading, error, data }) => {
        if (loading) return <Spinner />
        if (error) return `Error! ${error}`
        const relatedArticles =
          data.tagCollection.items[0].linkedFrom.newsArticleCollection.items
            .filter((caller) => caller.sys.id !== props.caller)
            .sort((a, b) => {
              return (
                +new Date(a.sys.firstPublishedAt) -
                +new Date(b.sys.firstPublishedAt)
              )
            })
            .reverse()
            .slice(0, 4)

        return relatedArticles.map((article) => (
          <Query
            key={article.sys.id}
            query={GET_ARTICLE_BY_ID}
            variables={{ id: article.sys.id }}
          >
            {({ loading, error, data }) => {
              if (loading) return <Spinner />
              if (error) return `Error! ${error}`
              const {
                image,
                sys: { firstPublishedAt },
                title,
                subtitle,
                location,
                linkedFrom: { urlSlugCollection },
              } = data.newsArticleCollection.items[0]

              return (
                <Link to={`/${localeLower}/${urlSlugCollection.items[0].slug}`}>
                  <FlexCard
                    width={{ md: 12 }}
                    mediaWidth={{ md: 4 }}
                    smallSpacing={true}
                    media={<AspectImage src={image.url} />}
                  >
                    <p
                      css={css`
                        font-size: 15px;
                        line-height: 18px;
                        margin: 0 0 5px;
                        margin-bottom: 5px;
                      `}
                    >
                      {`${location}, ${formatDate(firstPublishedAt)}`}
                    </p>
                    <Headline
                      as={'h4'}
                      css={css`
                        margin: 0 0 5px;
                        font-size: 16px;
                        line-height: 21px;
                        color: ${theme.colors.text.secondary.primary};
                      `}
                    >
                      {title}
                    </Headline>
                    <p
                      css={css`
                        overflow: hidden;

                        ${media.greaterThan('m')`
                          height: 1.5em;
                        `}
                      `}
                    >
                      {subtitle}
                    </p>
                  </FlexCard>
                </Link>
              )
            }}
          </Query>
        ))
      }}
    </Query>
  )
}

export default RelatedContent
